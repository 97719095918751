import Long from 'long';
import { log } from './log';
import { Message, Signal } from './models/can-database';

export class DataPack {
    static encodeHexString(str: string): Uint8Array {
        const bytesArr = [];
        str = str
            .padEnd(str.length + (str.length % 2), '0')
            .replace(/\s*/gi, '');
        for (let i = 0; i < str.length; i += 2) {
            bytesArr.push(parseInt(str.slice(i, i + 2), 16));
        }
        return new Uint8Array(bytesArr);
    }

    static encode(
        values: { [key: string]: number },
        message: Message,
    ): Uint8Array {
        let bits = setLength('', message.dataLength * 8);
        for (const signal of message.signals) {
            bits = DataPack.storeSignal(bits, values[signal.name] ?? 0, signal);
        }
        return new Uint8Array(bitsToBytes(bits, message.dataLength));
    }

    private static storeSignal(
        frameBits: string,
        value: number,
        signal: Signal,
    ): string {
        value = Math.floor((value - signal.offset) / signal.scale);
        // Use Long to make sure we handle two's complement correctly.
        const valueBits = setLength(
            Long.fromNumber(value, false)
                .toBytes()
                .map((b) => b.toString(2).padStart(8, '0'))
                .join(''),
            signal.length,
        );
        if (signal.isLittleEndian) {
            return writeLittleEndian(frameBits, valueBits, signal);
        } else {
            return writeBigEndian(frameBits, valueBits, signal);
        }
    }

    static decode(
        frame: Uint8Array,
        message: Message,
    ): { [key: string]: number } {
        const messageValues = {};
        // Iterate over the signals for this CAN message
        for (const signal of message.signals) {
            const value = DataPack.extractSignal(frame, signal);
            // Bound check the value.
            if (value <= signal.maximum && value >= signal.minimum) {
                // TODO: Check to see if an enum is used.
                //       If so, we need to look up the enum value.
                //       Otherwise, just provide the calculated value.
                messageValues[signal.name] = value;
            } else {
                log.error('VALUE OUT OF BOUNDS:', signal.name, value);
            }
        }
        return messageValues;
    }

    // TODO: rewrite this
    private static extractSignal(frame: Uint8Array, signal: Signal): number {
        const { length, isLittleEndian, isSigned } = signal;
        let startingBit = signal.startingBit;
        // If using Motorola format, calculate the correct start bit
        // because it's backwards like that
        if (!isLittleEndian) {
            const NUMBER_OF_BITS_IN_ONE_BYTE = 8; // total number of bits in one byte
            const LAST_BIT_LOC_IN_BYTE = NUMBER_OF_BITS_IN_ONE_BYTE - 1; // last bit location in a byte (e.g., 0,1,2,3,4,5,6,7 here 7 is the last one)
            const init_start_byte = Math.floor(
                startingBit / NUMBER_OF_BITS_IN_ONE_BYTE,
            ); // calculate starting bit byte location
            const start_bit_loc_inside_byte =
                startingBit % NUMBER_OF_BITS_IN_ONE_BYTE; // calculate start bit location inside a byte
            const end_bit =
                init_start_byte * NUMBER_OF_BITS_IN_ONE_BYTE +
                LAST_BIT_LOC_IN_BYTE -
                start_bit_loc_inside_byte +
                length -
                1; // calculate end bit location
            const end_bit_byte_loc = Math.floor(
                end_bit / NUMBER_OF_BITS_IN_ONE_BYTE,
            ); // calculate ending bit byte location
            const mirror_end_bit_in_byte =
                LAST_BIT_LOC_IN_BYTE - (end_bit % NUMBER_OF_BITS_IN_ONE_BYTE); // calculate mirror end bit location inside a byte
            startingBit =
                end_bit_byte_loc * NUMBER_OF_BITS_IN_ONE_BYTE +
                mirror_end_bit_in_byte; // calculate actual start bit
        }

        const startByte = Math.floor(startingBit / 8);
        const startBitInByte = startingBit % 8;
        let currentTargetLength = 8 - startBitInByte;
        let endByte = 0;
        let count = 0;

        // Write first bits to target
        // tslint:disable-next-line:no-bitwise
        let testTarget = new Long(frame[startByte] >> startBitInByte, 0, true);

        // Write residual bytes
        if (isLittleEndian) {
            // Intel (little endian)
            endByte = (startingBit + length - 1) / 8;
            for (count = startByte + 1; count <= endByte; count++) {
                testTarget = testTarget.or(
                    Long.fromInt(frame[count], true).shiftLeft(
                        currentTargetLength,
                    ),
                );
                currentTargetLength += 8;
            }
        } else {
            // Motorola (big endian)
            endByte = (startByte * 8 + 8 - startBitInByte - length) / 8;
            for (count = startByte - 1; count >= endByte; count--) {
                testTarget = testTarget.or(
                    Long.fromInt(frame[Math.abs(count)], true).shiftLeft(
                        currentTargetLength,
                    ),
                );
                currentTargetLength += 8;
            }
        }

        // Mask value
        // tslint:disable-next-line:no-bitwise
        testTarget = testTarget.and(
            new Long(0xffffffff, 0xffffffff, true).shiftRightUnsigned(
                64 - length,
            ),
        );

        // perform sign extension
        if (isSigned) {
            if (length > 32) {
                testTarget = testTarget.toSigned();
            } else {
                // tslint:disable-next-line:no-bitwise
                const msk = 1 << (length - 1);
                // tslint:disable-next-line:no-bitwise
                testTarget = Long.fromInt((testTarget.toNumber() ^ msk) - msk);
            }
        }
        return testTarget.toNumber() * signal.scale + signal.offset;
    }
}

// Write the signal value to the frame in little endian format.
// Don't modify this unless you are sure you know what you are doing, and you
// have tested thoroughly. See `data-pack.spec.ts`.
function writeLittleEndian(
    frameBits: string,
    valueBits: string,
    signal: Signal,
): string {
    const startByte = Math.floor(signal.startingBit / 8);
    const startBitInByte = signal.startingBit % 8;
    let usedBytes = 0;
    let out = '';
    if (startBitInByte > 0) {
        out += shiftLeft(
            valueBits.slice(-(8 - startBitInByte)),
            startBitInByte,
        );
        usedBytes += 1;
        valueBits = valueBits.slice(0, -(8 - startBitInByte));
    }
    while (valueBits.length > 8) {
        const chunk = valueBits.slice(-8);
        out += chunk;
        usedBytes += 1;
        valueBits = valueBits.slice(0, -8);
    }
    if (valueBits.length > 0) {
        out += valueBits.padStart(8, '0');
        usedBytes += 1;
    }

    // This refers to the index of the last bit generated by
    // convertToLittleEndian. This is not necessarily the index of the
    // last bit of the actual value.
    const endBit = (startByte + usedBytes) * 8;
    out = shiftLeft(out, frameBits.length - endBit);
    return writeBits(frameBits, out);
}

// Write the signal value to the frame in big endian format.
// Don't modify this unless you are sure you know what you are doing, and you
// have tested thoroughly. See `data-pack.spec.ts`.
function writeBigEndian(
    frameBits: string,
    valueBits: string,
    signal: Signal,
): string {
    // DO NOT MODIFY WITHOUT THOROUGH TESTING, this handles the wrapping
    // behavior of big endian signals
    valueBits = shiftLeft(
        valueBits,
        signal.startingBit +
            frameBits.length -
            7 -
            signal.length -
            Math.floor(signal.startingBit / 8) * 16,
    );
    return writeBits(frameBits, valueBits);
}

// Write the value bits to the frame bits.
function writeBits(frameBits: string, valueBits: string) {
    valueBits = setLength(valueBits, frameBits.length);
    let out = '';
    for (let i = frameBits.length - 1; i >= 0; i--) {
        const newBool = frameBits[i] == '1' || valueBits[i] == '1';
        out = (newBool ? '1' : '0') + out;
    }
    return out;
}

function bitsToBytes(bitsString: string, numBytes: number): number[] {
    bitsString = bitsString.padStart(numBytes * 8, '0');
    const out = [];
    for (let i = 0; i < numBytes * 8; i += 8) {
        out.push(parseInt(bitsString.slice(i, i + 8), 2));
    }
    return out;
}

function shiftLeft(bitsString: string, num: number): string {
    for (let i = 0; i < num; i++) {
        bitsString += '0';
    }
    return bitsString;
}

function setLength(bitsString: string, length: number): string {
    if (bitsString.length > length) {
        // truncate extra leading bits
        bitsString = bitsString.slice(bitsString.length - length);
    } else if (bitsString.length < length) {
        // fill in leading 0 bits
        bitsString = bitsString.padStart(length, '0');
    }
    return bitsString;
}
